import React from "react";
import LegalTextBlock from "../components/LegalTextBlock";
import LegalHeading3 from "../components/LegalHeading3";
import LegalHeading2 from "../components/LegalHeading2";
// import LegalList from "../components/LegalList";

function dataDeletion(props) {
  return (
    <div className="max-w-5xl py-10 mx-auto text-left">
      <LegalHeading2>Data deletion</LegalHeading2>
     <LegalTextBlock>
     Thank you for using Ooofer. We understand that privacy is important to you and we respect your decision to take control of your online data.<br/>
     To delete your data from Ooofer, please follow the steps below:<br/>
    </LegalTextBlock>
     <LegalTextBlock>
     <ol className="ml-5 list-decimal">
        <li>
        Send an email to info[at]ooofer.com, from the email address associated with your Ooofer account.
        </li>
        <li>
            In the email, please include the following information:
            <ul className="ml-10 list-disc">
                <li>
                Your full name
                </li>
                <li>
                Your Ooofer account email or phone number
                </li>
                <li>
                A statement that you wish to terminate your Ooofer account and delete all associated data.
                </li>
            </ul>
        </li>
        <li>
        Our team will review your request and delete your data within 30 days of receiving your email.
        </li>
     </ol>
    </LegalTextBlock>
     <LegalTextBlock>
        Please note that once your data is deleted, it cannot be recovered. This includes all of your profile info, bookings, photos, messages, and other content that you have shared on Ooofer.
    </LegalTextBlock>
     <LegalTextBlock>
    We appreciate the time you spent on Ooofer and hope that you had a positive experience on our platform. If you have any questions or concerns about the data termination process, please don't hesitate to contact us at info[at]ooofer.com.
    </LegalTextBlock>
      
    </div>
  );
}

export default dataDeletion;
